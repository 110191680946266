import React from 'react';

import {Input, Col, Row} from 'antd';

import logo from './ollm_logo_v2.png';
import './App.css';


function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <img src={logo} className="App-logo" alt="logo"/>
            </header>
            {/*<Row>*/}
            {/*    <Col span={8} offset={8}>*/}
            {/*        <Input.Search className="center-input" size="large" placeholder="input here" enterButton/>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    );
}

export default App;
